import React, { useEffect, useState } from 'react'
import { Redirect, RouteComponentProps } from 'react-router-dom'
// import { web3 } from '../../utils/playerAddr'

// Redirects to swap but only replace the pathname
export function RedirectPathToSwapOnly({ location }: RouteComponentProps) {
  const [pathName, setPathName] = useState('')
  //
  useEffect(() => {
    setPathName('/swap')
    // web3()
    //   .eth.net.getId()
    //   .then((r: any) => {
    //     setPathName(r === 128 ? '/acrossChain' : '/swap')
    //   })
  }, [])
  return <Redirect to={{ ...location, pathname: pathName }} />
}

// Redirects from the /swap/:outputCurrency path to the /swap?outputCurrency=:outputCurrency format
export function RedirectToSwap(props: RouteComponentProps<{ outputCurrency: string }>) {
  const {
    location: { search },
    match: {
      params: { outputCurrency }
    }
  } = props

  return (
    <Redirect
      to={{
        ...props.location,
        pathname: '/swap',
        search:
          search && search.length > 1
            ? `${search}&outputCurrency=${outputCurrency}`
            : `?outputCurrency=${outputCurrency}`
      }}
    />
  )
}
