import React, { Suspense, useState, useEffect } from 'react'
import intl from 'react-intl-universal'
import { HashRouter, Route, Switch } from 'react-router-dom'
import styled from 'styled-components'
import GoogleAnalyticsReporter from '../components/analytics/GoogleAnalyticsReporter'
import Popups from '../components/Popups'
import Web3ReactManager from '../components/Web3ReactManager'
import DarkModeQueryParamReader from '../theme/DarkModeQueryParamReader'
import AddLiquidity from './AddLiquidity'
import {
  RedirectDuplicateTokenIds,
  RedirectOldAddLiquidityPathStructure,
  RedirectToAddLiquidity
} from './AddLiquidity/redirects'
import MigrateV1 from './MigrateV1'
import MigrateV1Exchange from './MigrateV1/MigrateV1Exchange'
import RemoveV1Exchange from './MigrateV1/RemoveV1Exchange'
import Pool from './Pool'
import PoolFinder from './PoolFinder'
import RemoveLiquidity from './RemoveLiquidity'
import { RedirectOldRemoveLiquidityPathStructure } from './RemoveLiquidity/redirects'
import Swap from './Swap'
import { RedirectPathToSwapOnly, RedirectToSwap } from './Swap/redirects'
import BankIncome from './Bank/BankIncome'
import BankIncomeList from './Bank/BankIncomeList'
import NameList from './Bank/NameList'
import JianghuOrder from './JianghuOrder/JianghuOrder'
import InvitationDetails from './JianghuOrder/InvitationDetails'
import EnergyDetails from './JianghuOrder/EnergyDetails'
import AcrossChain from './AcrossChain/AcrossChain'
import Exchange from './AcrossChain/Exchange'
import Record from './AcrossChain/Record'
import Test from './AcrossChain/test'
const AppWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  overflow-x: hidden;
`

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 30px;
  align-items: center;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 10;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
      padding: 4%;
  `};

  z-index: 1;
`

const Marginer = styled.div`
  margin-top: 5rem;
`
const locales = {
  en: require('../locales/en.json'),
  ch: require('../locales/zh-CN.json'),
  tw: require('../locales/zh-TW.json'),
  japanese: require('../locales/japanese.json'),
  korean: require('../locales/korean.json')
}

export default function App() {
  const [initDone, setInitDone] = useState<boolean>(false)
  const loadLocales = () => {
    // react-intl-universal 是单例模式, 只应该实例化一次
    if (!localStorage.getItem('localee')) {
      localStorage.setItem('localee', 'en')
    }
    intl.init({ currentLocale: localStorage.getItem('localee') || 'en', locales }).then(() => {
      setInitDone(true)
    })
  }
  useEffect(() => {
    loadLocales()
  }, [])
  return initDone ? (
    <Suspense fallback={null}>
      <HashRouter>
        <Route component={GoogleAnalyticsReporter} />
        <Route component={DarkModeQueryParamReader} />
        <AppWrapper>
          <BodyWrapper>
            <Popups />
            <Web3ReactManager>
              <Switch>
                <Route exact strict path="/swap" component={Swap} />
                <Route exact strict path="/swap/:outputCurrency" component={RedirectToSwap} />
                <Route exact strict path="/send" component={RedirectPathToSwapOnly} />
                <Route exact strict path="/find" component={PoolFinder} />
                <Route exact strict path="/pool" component={Pool} />
                <Route exact strict path="/bankIncome" component={BankIncome} />
                <Route exact strict path="/bankIncomeList" component={BankIncomeList} />
                <Route exact strict path="/nameList" component={NameList} />
                <Route exact strict path="/jianghuOrder" component={JianghuOrder} />
                <Route exact strict path="/invitationDetails" component={InvitationDetails} />
                <Route exact strict path="/energyDetails" component={EnergyDetails} />
                <Route exact strict path="/acrossChain" component={AcrossChain} />
                <Route exact strict path="/exchange" component={Exchange} />
                <Route exact strict path="/record/:sendType" component={Record} />
                <Route exact strict path="/test" component={Test} />
                <Route exact strict path="/create" component={RedirectToAddLiquidity} />
                <Route exact path="/add" component={AddLiquidity} />
                <Route exact path="/add/:currencyIdA" component={RedirectOldAddLiquidityPathStructure} />
                <Route exact path="/add/:currencyIdA/:currencyIdB" component={RedirectDuplicateTokenIds} />
                <Route exact strict path="/remove/v1/:address" component={RemoveV1Exchange} />
                <Route exact strict path="/remove/:tokens" component={RedirectOldRemoveLiquidityPathStructure} />
                <Route exact strict path="/remove/:currencyIdA/:currencyIdB" component={RemoveLiquidity} />
                <Route exact strict path="/migrate/v1" component={MigrateV1} />
                <Route exact strict path="/migrate/v1/:address" component={MigrateV1Exchange} />
                <Route component={RedirectPathToSwapOnly} />
              </Switch>
            </Web3ReactManager>
            <Marginer />
          </BodyWrapper>
        </AppWrapper>
      </HashRouter>
    </Suspense>
  ) : (
    <div></div>
  )
}
