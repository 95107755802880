import React, { useMemo } from 'react'
import { Pair } from '@uniswap/sdk'

import FullPositionCard from '../../components/PositionCard'
import { useTokenBalancesWithLoadingIndicator } from '../../state/wallet/hooks'
import { TYPE } from '../../theme'
import { LightCard } from '../../components/Card'
import { AutoColumn } from '../../components/Column'
import Loader from '../../components/Loader'
import { useActiveWeb3React } from '../../hooks'
import { usePairs } from '../../data/Reserves'
import { toV2LiquidityToken, useTrackedTokenPairs } from '../../state/user/hooks'
import AppBody from '../AppBody'
import PoolHeader from '../../components/Header/poolHeader'
import intl from 'react-intl-universal'
import { Text } from 'rebass'
import { Link } from 'react-router-dom'
import { ButtonLiquidity } from '../../components/Button'
import styled from 'styled-components'
import Footer from '../../components/Foot/foot'

const NoLiquidity = styled.div`
  width: 100%;
  height: 148px;
  border: 1px solid #485053;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #120d11;
`

export default function Pool() {
  const { account } = useActiveWeb3React()
  // fetch the user's balances of all tracked V2 LP tokens
  const trackedTokenPairs = useTrackedTokenPairs()
  const tokenPairsWithLiquidityTokens = useMemo(
    () => trackedTokenPairs.map(tokens => ({ liquidityToken: toV2LiquidityToken(tokens), tokens })),
    [trackedTokenPairs]
  )
  const liquidityTokens = useMemo(() => tokenPairsWithLiquidityTokens.map(tpwlt => tpwlt.liquidityToken), [
    tokenPairsWithLiquidityTokens
  ])
  const [v2PairsBalances, fetchingV2PairBalances] = useTokenBalancesWithLoadingIndicator(
    account ?? undefined,
    liquidityTokens
  )

  // fetch the reserves for all V2 pools in which the user has a balance
  const liquidityTokensWithBalances = useMemo(
    () =>
      tokenPairsWithLiquidityTokens.filter(({ liquidityToken }) =>
        v2PairsBalances[liquidityToken.address]?.greaterThan('0')
      ),
    [tokenPairsWithLiquidityTokens, v2PairsBalances]
  )

  const v2Pairs = usePairs(liquidityTokensWithBalances.map(({ tokens }) => tokens))
  const v2IsLoading =
    fetchingV2PairBalances || v2Pairs?.length < liquidityTokensWithBalances.length || v2Pairs?.some(V2Pair => !V2Pair)

  const allV2PairsWithLiquidity = v2Pairs.map(([, pair]) => pair).filter((v2Pair): v2Pair is Pair => Boolean(v2Pair))
  return (
    <>
      <AppBody>
        <PoolHeader url={window.location.href} />
        <div style={{ height: 60 }}></div>
        <div style={{ fontSize: 12, color: 'white', marginBottom: 20 }}>{intl.get('yourCapital')}</div>
        <AutoColumn
          gap="lg"
          justify="center"
          style={{
            paddingBottom: '140px'
          }}
        >
          <AutoColumn
            gap="12px"
            style={{
              color: '#fff',
              border: '0',
              width: '100%',
              borderRadius: 8
            }}
          >
            {!account ? (
              <LightCard padding="40px">
                <TYPE.body style={{ color: 'white' }}>
                  {intl.get('connectWallet')} {/* Connect to a wallet to view your liquidity. */}
                </TYPE.body>
              </LightCard>
            ) : v2IsLoading ? (
              <LightCard padding="40px">
                <TYPE.body>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <text style={{ color: 'white', marginRight: 10 }}> Loading </text>
                    <Loader />
                  </div>
                  {/* <Dots style={{ color: 'white' }}>Loading</Dots> */}
                </TYPE.body>
              </LightCard>
            ) : allV2PairsWithLiquidity?.length > 0 ? (
              <>
                {allV2PairsWithLiquidity.map(v2Pair => (
                  <FullPositionCard key={v2Pair.liquidityToken.address} pair={v2Pair} />
                ))}
              </>
            ) : (
              <NoLiquidity>
                <Text fontSize={12} marginBottom="16px">{intl.get('noLiquidityFound')}</Text>
                <ButtonLiquidity as={Link} to={`/add`} width="30%">
                  {intl.get('confirmAdd')}
                </ButtonLiquidity>
              </NoLiquidity>
            )}
          </AutoColumn>
        </AutoColumn>
      </AppBody>
      <Footer url={window.location.href} />
    </>
  )
}
