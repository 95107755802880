import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import SwapBright from '../../assets/images/footImg/swapBright.png'
import SwapGray from '../../assets/images/footImg/swapGray.png'
import PoolBright from '../../assets/images/footImg/poolBright.png'
import PoolGray from '../../assets/images/footImg/poolGray.png'
import FomoIcon from '../../assets/images/footImg/fomoIcon.png'
import JiangHuOrderBright from '../../assets/images/footImg/JiangHuOrderBright.png'
import JiangHuOrderGray from '../../assets/images/footImg/JiangHuOrderGray.png'
import BridgeBright from '../../assets/images/footImg/bridgeBright.png'
import BridgeGray from '../../assets/images/footImg/bridgeGray.png'
import { fomoUrl } from '../../store/constants'
import { web3 } from '../../utils/playerAddr'
import styles from '../../style/foot.module.css'

// import intl from 'react-intl-universal'

const FootFrame = styled.div`
  width: 100%;
  bottom: 0;
  bottom: constant(safe-area-inset-bottom);
  bottom: env(safe-area-inset-bottom);
  position: fixed;
  background: #000000;
  height: 83px;
  border-top: 1px solid #333334;
  padding-top: 7px;
  z-index: 3;
`

const FootElement = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  height: 54px;
`
const Link = styled.a`
  width: 43px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
`
const Icon = styled.img`
  height: auto;
  width: 26px;
`
const LinkTitle = styled.div`
  margin-top: 4px;
  font-size: 12px;
`

export default function Foot({ url }) {
  const [flag, setFlag] = useState(false)
  const [menuList, setMenuList] = useState([
    { title: 'Swap', href: '#/swap', iconB: SwapBright, iconG: SwapGray, flag: false },
    { title: 'Pool', href: '#/bankIncomeList', iconB: PoolBright, iconG: PoolGray, flag: false },
    { title: '', href: fomoUrl, iconB: FomoIcon, iconG: FomoIcon, flag: false },
    {
      title: 'Reward',
      href: '#/jianghuOrder',
      iconB: JiangHuOrderBright,
      iconG: JiangHuOrderGray,
      flag: false
    },
    { title: 'Bridge', href: '#/acrossChain', iconB: BridgeBright, iconG: BridgeGray, flag: false }
  ])

  const getMenuColor = () => {
    let list = menuList
    list.forEach(item => {
      item.flag = false
      if (url.search(item.href) !== -1) {
        item.flag = true
      }
    })
    if (url.search('#/add') !== -1 || url.search('#/pool') !== -1) {
      list.forEach(item => {
        item.flag = false
      })
      list[1].flag = true
    }
    setMenuList(list)
  }

  useEffect(() => {
    getMenuColor()
    web3()
      .eth.net.getId()
      .then(r => {
        if (r === 128) {
          setFlag(true)
        }
      })
  }, [])
  return (
    <>
      <FootFrame style={{ display: flag ? 'none' : 'block' }} className={styles.foot}>
        <FootElement>
          {menuList.map((item, index) => (
            <Link key={index} href={item.href}>
              <Icon
                style={{ width: index === 2 ? 56 : 26, marginTop: index === 2 ? -20 : 0 }}
                src={item.flag ? item.iconB : item.iconG}
                alt="logo"
              />
              <LinkTitle style={{ color: item.flag ? '#FB409B' : '#999999', display: index === 2 ? 'none' : 'block' }}>
                {item.title}
              </LinkTitle>
            </Link>
          ))}
        </FootElement>
      </FootFrame>
    </>
  )
}
