export const blankAddr = '0x0000000000000000000000000000000000000000'
export const testAddr = ''
export const serverLink = 'https://testservice.fivexw.com'
// export const serverLink = 'https://bscservice.fivexw.com'
//推广任务中邀请链接
// export const inviteUrl = 'http://192.168.1.117:3000/#'
export const inviteUrl = 'https://testswap.fivexw.com'

//五行入口
export const fomoUrl = 'https://testv1.fivexw.com/?utm_source=tokenpocket#/selectMode'

export const FomoActionAddress = '0xE6300167Bf35beF96101C7Da041eC343DfFbD5fC'
//test
export const shareAddress = '0x7fB089474f6D165a847A52cE042294911DC8c1bE'
export const contractAddr = '0xaA57cF9fB58Cf8F22f20B8427eb7c9b51ac6c56E'
export const scoreContractAddr = '0x8A6D6eD7d4C24bFEE067b8760a2435392aC6726A'
export const actionContractAddr = '0xE6300167Bf35beF96101C7Da041eC343DfFbD5fC'
export const rewardBagAddress = '0xFf8DCb7f356ae204544C25d6FF4e49350AEA09C1'
export const scoreDecimal = 18
export const tokenOptions = [
  {
    name: 'AURA',
    decimal: 18,
    address: '0x0B6F8848fCA58031fd612dDfc26ABA460A4929aE',
    unitDecimal: 0,
    unitName: ''
  },
  {
    name: 'HTHAM',
    decimal: 18,
    address: '0xCafA482773481435ACb227A5a08e6B4f49CD88Ce',
    unitDecimal: 8,
    unitName: '亿'
  },
  {
    name: 'MDX',
    decimal: 18,
    address: '0xF6a16e67b39E77B6622803CD1499224346135927',
    unitDecimal: 0,
    unitName: ''
  },
  {
    name: 'USDT',
    decimal: 18,
    address: '0x4572ac017fD735f9825eD7cA4488b93FbcF802f0',
    unitDecimal: 0,
    unitName: ''
  }
]
