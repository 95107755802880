import React, { useState, useEffect, useRef } from 'react'
import AppBody from '../AppBody'
import Decimal from 'decimal.js-light'
import './acrossChain.css'
import { serverLink } from '../../store/constants'
import {
  getDkContract,
  getDKSlippage,
  getDKaddr,
  getDK2addr,
  getDK2,
  getDK,
  DkToDK2Exchange,
  Dk2ToDKExchange,
  getUserAddress,
  execute,
  allowanceLP,
  getErc20Contract,
  getAccount
} from '../../utils/playerAddr'
import Spin from '../Bank/Spin'
import intl from 'react-intl-universal'
import change1 from '../../assets/images/acrosschain/change1.png'
// import change2 from '../../assets/images/acrosschain/change2.png'
import dk2Icon from '../../assets/images/dk2Icon.png'
import dkIcon from '../../assets/images/dkIcon.jpeg'
import record from '../../assets/images/acrosschain/record.png'
import Footer from '../../components/Foot/foot'

const Exchange = props => {
  const [flagError, setFlagError] = useState(false)
  const [countdown, setCountdown] = useState(2)
  const [loading, setLoading] = useState(false)
  const [messageError, setMessageError] = useState('')
  const [currencyFlag, setCurrencyFlag] = useState(true) //当前是dk兑换dk2还是dk2兑换dk
  const [DKExchangeAddr, setDKExchangeAddr] = useState('') //dk兑换合约地址
  const [transferAmount, setTransferAmount] = useState('')
  const [transferWallet, setTransferWallet] = useState('')
  const [userAddr, setUserAddr] = useState('')
  const [balance, setBalance] = useState(0)
  const [DKaddr, setDKaddr] = useState('')
  const [DK2addr, setDK2addr] = useState('')
  const [slippage, setSlippage] = useState(0) //滑点
  const [getNumber, setGetNumber] = useState(0) //预计实际可得
  const time = useRef(null)

  //倒计时
  const countdownFun = () => {
    setCountdown(2)
    time.current = setInterval(() => {
      setCountdown(countdown => countdown - 1)
    }, 1000)
  }
  const message = msg => {
    setLoading(false)
    setFlagError(true)
    setMessageError(msg)
    countdownFun()
  }
  const goAcrossChain = () => {
    props.history.push({ pathname: './acrossChain' })
  }
  const goRecord = () => {
    props.history.push({ pathname: './record/2' })
  }
  //余额
  const currentBalance = async (userAddr, addr) => {
    setLoading(true)
    let balance = 0
    balance = await execute(userAddr, addr)
    setLoading(false)
    return Math.floor(balance * 10000) / 10000
  }
  //切换 兑换币种
  const changeCurrency = async () => {
    setCurrencyFlag(!currencyFlag)
    setTransferAmount('')
    setGetNumber(0)
    let addr = !currencyFlag ? DKaddr : DK2addr
    setBalance(await currentBalance(userAddr, addr))
  }
  //预计实际可得改变
  const getNumberChange = async num => {
    let numNew = new Decimal(Number(num)).mul(new Decimal(10).pow(18)).toFixed()
    if (currencyFlag) {
      if (Number(num) >= 100000000) {
        setGetNumber(await getDK2(DKExchangeAddr, numNew))
      } else {
        setGetNumber(0)
      }
    } else {
      if (Number(num) >= 1) {
        setGetNumber(await getDK(DKExchangeAddr, numNew))
      } else {
        setGetNumber(0)
      }
    }
  }
  //最大值
  const changeMax = () => {
    if (userAddr) {
      setTransferAmount(balance)
      getNumberChange(balance)
    }
  }
  //转账金额改变
  const changeTransferAmount = async e => {
    e.persist()
    if (e.target.value >= 0) {
      setTransferAmount(e.target.value)
      getNumberChange(e.target.value)
    }
  }
  //转账钱包地址
  const changeTransferWallet = e => {
    e.persist()
    setTransferWallet(e.target.value)
  }
  //设置项
  const getConfig = async userAddr => {
    setLoading(true)
    try {
      const res = await (
        await fetch(`${serverLink}/api/get-swap-dk-convert-config
      `)
      ).json()
      if (res.code === 0) {
        setLoading(false)
        let data = res.data.dk_convert_pool_address
        setDKExchangeAddr(data)
        setSlippage(await getDKSlippage(data))
        let DKaddr = await getDKaddr(data)
        setDKaddr(DKaddr)
        setDK2addr(await getDK2addr(data))
        setBalance(await currentBalance(userAddr, DKaddr))
      } else {
        message(res.msg)
      }
    } catch (e) {
      message(e.message)
    }
  }
  const getCollectionToken = async () => {
    setLoading(true)
    let num = new Decimal(Number(transferAmount)).mul(new Decimal(10).pow(18)).toFixed()
    try {
      if (currencyFlag) {
        await DkToDK2Exchange(DKExchangeAddr, num, transferWallet, userAddr)
      } else {
        await Dk2ToDKExchange(DKExchangeAddr, num, transferWallet, userAddr)
      }
      let addr = currencyFlag ? DKaddr : DK2addr
      setBalance(await currentBalance(userAddr, addr))
      setTransferAmount('')
      setTransferWallet('')
      setGetNumber(0)
      message(intl.get('exSuccess'))
    } catch (e) {
      message(e.message)
    }
  }
  //连接钱包
  const { ethereum } = window
  const isMetaMask = !!(ethereum && ethereum.isMetaMask)
  const toggleWalletModal = async () => {
    if (isMetaMask) {
      await getAccount()
      getUserAddress().then(r => {
        setUserAddr(r)
      })
    } else {
      message(intl.get('metaMask'))
    }
  }
  //兑换
  const transfer = async () => {
    if (!userAddr) {
      toggleWalletModal()
      return
    }
    if (transferAmount === 0 || transferAmount === '') {
      message(intl.get('inputExAm'))
      return
    }
    if (transferAmount > balance) {
      message(intl.get('exMax'))
      return
    }
    if (currencyFlag) {
      if (transferAmount < 100000000) {
        message(intl.get('minimum1'))
        return
      }
    } else {
      if (transferAmount < 1) {
        message(intl.get('minimum2'))
        return
      }
    }
    if (transferWallet === '') {
      message(intl.get('pleaseFillwa'))
      return
    }

    setLoading(true)
    let allowAcount = await allowanceLP(
      userAddr,
      DKExchangeAddr, //dk兑换合约地址
      currencyFlag ? DKaddr : DK2addr //dk合约
    )
    setLoading(false)
    let amount = 90000000000000000000000000000000000000000000000000000000000
    let amount1 = new Decimal(amount).mul(new Decimal(10).pow(5)).toFixed()
    if (transferAmount > Number(allowAcount)) {
      setLoading(true)
      try {
        await getErc20Contract(currencyFlag ? DKaddr : DK2addr)
          .methods.approve(DKExchangeAddr, amount1)
          .send({ from: userAddr })
          .then(r => {
            message(intl.get('successConTr'))
            getCollectionToken()
          })
      } catch (e) {
        message(e.message)
      }
      return
    }
    getCollectionToken()
  }
  useEffect(() => {
    if (countdown < 0) {
      setFlagError(false)
      setMessageError('')
      clearInterval(time.current)
    }
  }, [countdown])

  useEffect(() => {
    getUserAddress().then(addr => {
      setUserAddr(addr)
      getConfig(addr)
    })
  }, [])

  return (
    <>
      {loading ? (
        <div className="spin-bg">
          <Spin />
        </div>
      ) : null}
      <AppBody></AppBody>
      <div className="ac-menu">
        <div className="ac-menuCon">
          <div className="ac-menuLeft">
            <div
              className="ac-select"
              onClick={() => {
                goAcrossChain()
              }}
            >
              <div>{intl.get('acrossChain')}</div>
              {/* <div className="ex-menuLine"></div> */}
            </div>
            <div className="ac-selected">
              <div>{intl.get('exchange')}</div>
              <div className="ex-menuLine"></div>
            </div>
          </div>
          <div
            className="ac-lookRecord"
            onClick={() => {
              goRecord()
            }}
          >
            <img src={record} alt="" />
            <span>{intl.get('lookRecord')}</span>
          </div>
        </div>
      </div>

      <div className="ac-changeChain">
        <img className="ac-changeChainIcon" src={change1} onClick={changeCurrency} />
        <div className="ac-fromTo">
          <div>From</div>
          <img src={currencyFlag ? dkIcon : dk2Icon} />
          <div>{currencyFlag ? 'DogeKing' : 'DogeKing2'} </div>
        </div>
        <div className="ac-fromTo">
          <div>To</div>
          <img src={currencyFlag ? dk2Icon : dkIcon} />
          <div>{currencyFlag ? 'DogeKing2' : 'DogeKing'}</div>
        </div>
      </div>
      <div className="ac-transfer">
        <div className="ac-transferAmount" style={{ marginTop: 16 }}>
          <input
            inputMode="decimal"
            type="number"
            pattern="^(0x[a-fA-F0-9]{40})$"
            value={transferAmount}
            placeholder={currencyFlag ? intl.get('minimum1') : intl.get('minimum2')}
            onChange={e => changeTransferAmount(e)}
          />
          <div
            onClick={() => {
              changeMax()
            }}
          >
            MAX
          </div>
        </div>
        <div style={{ color: '#E5CF80', fontSize: '11px', marginTop: '8px' }}>
          {intl.get('currentHave')}
          {currencyFlag ? 'DogeKing' : 'DogeKing2'}：{balance}
        </div>
        <div className="ac-wallet">
          <input
            placeholder={intl.get('pleaseFillwa')}
            value={transferWallet}
            onChange={e => changeTransferWallet(e)}
          />
        </div>
        <div className="ac-handlingFee">
          <div>
            {intl.get('slippage')} {slippage}%
          </div>
          <div>{Math.floor(((getNumber * 10) / (100 - slippage)) * 10000) / 10000}</div>
        </div>
        <div className="ac-get">
          <div>{intl.get('estimatedA')}</div>
          <div>{Math.floor(getNumber * 10000) / 10000}</div>
        </div>
        <div
          className="ac-transferButton"
          onClick={() => {
            transfer()
          }}
        >
          {userAddr ? intl.get('exchange') : intl.get('connectWallet')}
        </div>
      </div>
      <Footer url={window.location.href} />
      <div className="modal" style={{ display: flagError ? 'flex' : 'none' }}>
        <div className="modalContent">
          <div style={{ color: 'red', fontSize: 12 }}>{messageError}</div>
        </div>
      </div>
    </>
  )
}

export default Exchange
