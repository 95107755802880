import React, { useEffect, useRef, useState } from 'react'
import copy from 'copy-to-clipboard'
import Spin from '../Bank/Spin'
import './jianghuOrder.css'
import { serverLink, inviteUrl } from '../../store/constants'
import { getUserAddress, getRewardContract } from '../../utils/playerAddr'
import energyLogo from '../../assets/images/jianghuOrder/energyLogo.png'
import explorerGrey from '../../assets/images/jianghuOrder/explorerGrey.png'
import explorerBright from '../../assets/images/jianghuOrder/explorerBright.png'
import adventurerGrey from '../../assets/images/jianghuOrder/adventurerGrey.png'
import adventurerBright from '../../assets/images/jianghuOrder/adventurerBright.png'
import intl from 'react-intl-universal'
import Footer from '../../components/Foot/foot'
import GameNode from '../../components/JianghuOrder/gameNode'
const JianghuOrder = props => {
  const [loading, setLoading] = useState(false)
  const [currentTab, setCurrentTab] = useState(0)
  const [taskList, setTaskList] = useState([])
  const [seasonData, setSeasonData] = useState({})
  const [promoteData, setPromoteData] = useState({})
  const [flagError, setFlagError] = useState(false)
  const [messageError, setMessageError] = useState('')
  const [countdown, setCountdown] = useState(2)
  const [userAddress, setUserAddress] = useState('')
  const [inviteFriendFlag, setInviteFriendFlag] = useState(false)
  const [rewardData, setRewardData] = useState([])
  const [superior, setSuperior] = useState('')
  const [myWeight, setMyWeight] = useState(0)
  const [dailyTaskData, setDailyTaskData] = useState([])
  const [gameNodeTaskData, setGameNodeTaskData] = useState({})
  const time = useRef(null)
  const arrTab = [intl.get('LPTask'), intl.get('dailyPromotion'), intl.get('gameNode')]
  const lookDetail = goFlag => {
    if (goFlag === 1) {
      props.history.push({ pathname: './energyDetails' })
    } else if (goFlag === 2) {
      props.history.push({ pathname: './invitationDetails' })
    }
  }

  //倒计时
  const countdownFun = () => {
    setCountdown(2)
    time.current = setInterval(() => {
      setCountdown(countdown => countdown - 1)
    }, 1000)
  }

  const message = msg => {
    setLoading(false)
    setFlagError(true)
    setMessageError(msg)
    countdownFun()
  }

  //赛季数据
  const getSeasonData = async address => {
    setLoading(true)
    try {
      const res = await (
        await fetch(`${serverLink}/api/get-season-data/${address}
      `)
      ).json()

      if (res.code === 0) {
        setLoading(false)
        let data = res.data
        data.nft1Aura = 0
        data.nft2Aura = 0
        if (data.nft1_before_season_reward_list) {
          data.nft1_before_season_reward_list.map(item => {
            if (item.nft_reward_token === 'AURA') {
              data.nft1Aura = Number(item.reward)
            }
          })
        }
        if (data.nft2_before_season_reward_list) {
          data.nft2_before_season_reward_list.map(item => {
            if (item.nft_reward_token === 'AURA') {
              data.nft2Aura = Number(item.reward)
            }
          })
        }
        let timestamp = data.end_time - Date.parse(new Date()) / 1000
        if (timestamp < 0) {
          data.countdownTime = '--'
        } else {
          let day = Math.floor(timestamp / (3600 * 24))
          let hour = Math.floor((timestamp / 3600) % 24)
          let m = Math.floor((timestamp / 60) % 60)
          data.countdownTime = day + intl.get('day') + hour + intl.get('hour')
        }
        localStorage.setItem('countdownTime', data.countdownTime)
        setSeasonData(data)
      } else {
        message(res.msg)
      }
    } catch (e) {
      message(e.message)
    }
  }
  //推广数据
  const getPromoteData = async address => {
    setLoading(true)
    try {
      const res = await (
        await fetch(`${serverLink}/api/get-ext-lp-panel/${address}
      `)
      ).json()
      if (res.code === 0) {
        setLoading(false)
        let data = res.data
        if (data.jackpot_list) {
          let sum = 0
          data.jackpot_list.map(item => {
            sum += Number(item.equal_u)
          })
          data.jackpotSum = sum
        }
        if (data.jackpot_list_not) {
          let sum = 0
          data.jackpot_list_not.map(item => {
            sum += Number(item.equal_u)
          })
          data.jackpotNotSum = sum
        }
        if (data.jackpot_list_yet) {
          let sum = 0
          data.jackpot_list_yet.map(item => {
            sum += Number(item.equal_u)
          })
          data.jackpotYetSum = sum
        }
        if (data.oneself_lp_total_reward_list) {
          let sum = 0
          data.oneself_lp_total_reward_list.map(item => {
            sum += Number(item.equal_u)
          })
          data.oneselfLpTotal = sum
        }
        setPromoteData(data)
      } else {
        message(res.msg)
      }
    } catch (e) {
      message(e.message)
    }
  }
  //任务列表
  const getTaskList = async address => {
    setLoading(true)
    try {
      const res = await (
        await fetch(`${serverLink}/api/player-invite-task/4/${address}
      `)
      ).json()
      if (res.code === 0) {
        setLoading(false)
        setTaskList(res.data)
      } else {
        message(res.msg)
      }
    } catch (e) {
      message(e.message)
    }
  }
  //去邀请
  const inviteFriendFun = async (num, id) => {
    console.log(id, 'ddd')
    copy(`${inviteUrl}/?paddr=${userAddress}`)
    setInviteFriendFlag(true)
    countdownFun()
    if (num === 2) {
      try {
        const res = await (
          await fetch(`${serverLink}/api/player-invite-task-succ/${id}/${userAddress}
            `)
        ).json()
        if (res.code === 0) {
          getTaskList(userAddress)
        } else {
          message(res.msg)
        }
      } catch (e) {
        message(e.message)
      }
    }
  }
  //一键领取
  const receiveAll = async () => {
    setLoading(true)
    try {
      const res = await (
        await fetch(`${serverLink}/api/draw-lp-ext-reward/${userAddress}
        `)
      ).json()
      if (res.code === 0) {
        message(res.msg)
        getPromoteData(userAddress)
        getTaskList(userAddress)
      } else {
        message(res.msg)
      }
    } catch (e) {
      message(e.message)
    }
  }
  //领取
  const receive = async (aura, type) => {
    console.log('auraaura', aura)
    if (aura !== 0) {
      setLoading(true)
      try {
        const res = await (
          await fetch(`${serverLink}/api/draw-season-reward/${userAddress}/${type}
          `)
        ).json()
        if (res.code === 0) {
          setLoading(false)
          message(res.msg)
          getSeasonData(userAddress)
          getPromoteData(userAddress)
        } else {
          message(res.msg)
        }
      } catch (e) {
        message(e.message)
      }
    }
  }
  //日常推广/游戏节点领取收益数据
  const getRewardData = async index => {
    setLoading(true)
    try {
      const res = await (
        await fetch(`${serverLink}/api/player/offer-daily-reward/${userAddress}/${index}
      `)
      ).json()
      if (res.code === 0) {
        setLoading(false)
        if ((index = 2)) {
          setMyWeight(res.data.weight)
        }
        setSuperior(res.data.parent_addr)
        setRewardData(res.data.rewards)
      } else {
        message(res.msg)
      }
    } catch (e) {
      message(e.message)
    }
  }
  //日常推广任务数据
  const getDailyTask = async () => {
    setLoading(true)
    try {
      const res = await (
        await fetch(`${serverLink}/api/player/direct-game-reward-total/${userAddress}
      `)
      ).json()
      if (res.code === 0) {
        setLoading(false)
        setDailyTaskData(res.data)
      } else {
        message(res.msg)
      }
    } catch (e) {
      message(e.message)
    }
  }
  //游戏节点任务数据
  const getGameNode = async () => {
    setLoading(true)
    try {
      const res = await (
        await fetch(`${serverLink}/api/player/node-game-task-state/${userAddress}
      `)
      ).json()
      if (res.code === 0) {
        setLoading(false)
        setGameNodeTaskData(res.data)
      } else {
        message(res.msg)
      }
    } catch (e) {
      message(e.message)
    }
  }
  const clickTab = index => {
    setCurrentTab(index)
    // if (index === 0) {
    // }
    getRewardData(index)
    if (index === 1) {
      getDailyTask()
    }
    if (index === 2) {
      getGameNode()
    }
  }
  //日常推广/游戏节点 一键领取
  const receiveAllFomo = async () => {
    setLoading(true)
    try {
      const res = await (
        await fetch(`${serverLink}/api/player/do-spread-reward-withdrawal/${userAddress}/${currentTab}
      `)
      ).json()
      if (res.code === 0) {
        let data = res.data
        await getRewardContract(data.pool_contract_address)
          .methods.distributeReward(data.tokens, data.amounts, data.deadline, data.v, data.r, data.s)
          .send({ from: userAddress })
          .then(() => {
            // getPromoteData(userAddress)
            // getRewardData(currentTab)
            //领取成功后 getRewardData(currentTab)返的数据有延迟，所以手动清零
            let list = rewardData
            list.map(item => {
              item.SumAmont = '0'
            })
            setRewardData(list)
            getDailyTask()
            getGameNode()
            message(intl.get('withdrawalS'))
          })
      } else {
        message(res.msg)
      }
    } catch (e) {
      message(e.message)
    }
  }
  useEffect(() => {
    if (countdown < 0) {
      setFlagError(false)
      setMessageError('')
      setInviteFriendFlag(false)
      clearInterval(time.current)
    }
  }, [countdown])
  useEffect(() => {
    // setTaskList(list)
    getUserAddress().then(r => {
      setUserAddress(r)
      getSeasonData(r)
      getPromoteData(r)
      getTaskList(r)
    })
  }, [])
  return (
    <>
      {loading ? (
        <div className="spin-bg">
          <Spin />
        </div>
      ) : null}

      <div className="backBlack">
        <div className="jho-income">
          <div className="promote">{intl.get('bountyPrizeP')}</div>
          <div className="promoteIV">{promoteData.jackpotSum}</div>
          <div className="distribute">
            <div className="distributeI">
              <div>{intl.get('distributedIncome')}</div>
              <div>{promoteData.jackpotYetSum}</div>
            </div>
            <div className="distributeI">
              <div>{intl.get('incomeToBe')}</div>
              <div>{promoteData.jackpotNotSum}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="jho-tab">
        {arrTab.map((item, index) => {
          return (
            <div
              onClick={() => clickTab(index)}
              className={currentTab === index ? 'jho-selected' : 'jho-noSelected'}
              key={index}
            >
              {item}
            </div>
          )
        })}
      </div>
      <div className="jho-task" style={{ display: currentTab === 0 ? 'block' : 'none' }}>
        <div className="payableIncome">
          <div className="payableIncomeTitle">
            <div>
              {intl.get('AccumulatedE')}
              <span className="payableIncomeTitleSpan" style={{ display: currentTab === 0 ? 'none' : 'inline' }}>
                {intl.get(currentTab === 1 ? 'reward24' : 'rewardWeek')}
              </span>
            </div>
            <div className="receiveAll" onClick={() => receiveAll()}>
              {intl.get('receiveAll')}
            </div>
          </div>
          {promoteData.oneself_lp_can_receive_reward_list &&
          promoteData.oneself_lp_can_receive_reward_list.length > 0 ? (
            promoteData.oneself_lp_can_receive_reward_list.map((item, index) => {
              return (
                <div className="payableIncomeCon" key={index}>
                  <div>
                    {item.token}
                    {intl.get('earnings')}
                  </div>
                  <div>{item.sum}</div>
                </div>
              )
            })
          ) : (
            <div className="payableIncomeCon">{intl.get('noIncomeCa')}</div>
          )}
        </div>
        {taskList.map((item, index) => {
          return (
            <div className="taskItem" key={index}>
              <div className="taskDetail">
                {/* <img src={taskImg} alt=""></img> */}
                <div>{item.task_content}</div>
                <div>{item.reward_content}</div>
              </div>

              {item.is_receive === 2 ? (
                <div className="jho-receive" onClick={() => inviteFriendFun(2, item.id)}>
                  {intl.get('toInvite')}
                </div>
              ) : (
                ''
              )}
              {item.is_receive === 4 ? (
                <div className="taskProgress">
                  <div>
                    {intl.get('ongoing')}
                    <span className="progressData">{item.not_num}</span>
                  </div>
                  {/* <div className="jho-progress">
                        <div className="jho-progressCurrent"></div>
                        <div className="jho-progressTotal"></div>
                    </div> */}
                </div>
              ) : (
                ''
              )}
            </div>
          )
        })}
      </div>
      <div className="jho-task" style={{ display: currentTab === 1 || currentTab === 2 ? 'block' : 'none' }}>
        <div className="jho-superior">
          <div>{intl.get('superior')}</div>
          <div>{superior ? superior : intl.get('noSuperior')}</div>
        </div>
        <div className="jho-superior" style={{ display: currentTab === 2 ? 'flex' : 'none' }}>
          <div>{intl.get('myWeights')}</div>
          <div style={{ color: '#65483C', fontWeight: 'bold' }}>{myWeight}%</div>
        </div>
        <div className="payableIncome">
          <div className="payableIncomeTitle">
            <div>
              {intl.get('AccumulatedE')}
              <span className="payableIncomeTitleSpan">{intl.get(currentTab === 1 ? 'reward24' : 'rewardWeek')}</span>
            </div>
            <div className="receiveAll" onClick={() => receiveAllFomo()}>
              {intl.get('receiveAll')}
            </div>
          </div>
          {rewardData.length > 0 ? (
            rewardData.map((item, index) => {
              return (
                <div className="payableIncomeCon" key={index}>
                  <div>
                    {item.TokenName}
                    {intl.get('earnings')}
                  </div>
                  <div>{item.SumAmont}</div>
                </div>
              )
            })
          ) : (
            <div className="payableIncomeCon">{intl.get('noIncomeCa')}</div>
          )}
        </div>
        <div style={{ display: currentTab === 1 ? 'block' : 'none' }}>
          {dailyTaskData.map((item, index) => {
            return (
              <div className="taskItem" key={index}>
                <div className="taskDetail">
                  <div>{item.game_type == 2 ? intl.get('dailyTaskTwo') : intl.get('dailyTask')}</div>
                  <div>
                    {intl.get('get')}
                    {item.ratio * 100}%{item.token}
                    {intl.get('reward')}
                  </div>
                </div>

                {item.sum > 0 ? (
                  <div className="taskProgress">
                    <div>
                      {intl.get('ongoing')}
                      <span className="progressData">{item.sum}</span>
                    </div>
                  </div>
                ) : (
                  <div className="jho-receive" onClick={() => inviteFriendFun(2, item.id)}>
                    {intl.get('toInvite')}
                  </div>
                )}
              </div>
            )
          })}
        </div>
        <div style={{ display: currentTab === 2 ? 'block' : 'none' }}>
          <div className="taskItem">
            <div className="taskDetail">
              <div>
                {intl.get('teamAchieve')}
                {gameNodeTaskData.node_team}U{intl.get('betA')}
              </div>
              <div style={{ color: '#4D3921', fontWeight: 'bold', fontSize: 13 }}>
                {intl.get('direct')}
                {gameNodeTaskData.node_direct_people}
                {intl.get('friendAchieve')}
                {gameNodeTaskData.node_direct_bet}U{intl.get('betA')}
              </div>
            </div>
            {gameNodeTaskData.finish > 0 ? (
              <div className="taskProgress">
                <div style={{ fontSize: 10 }}>
                  {gameNodeTaskData.finish === gameNodeTaskData.total ? intl.get('completed') : intl.get('ongoing')}
                  <span className="progressData">
                    {gameNodeTaskData.finish}/{gameNodeTaskData.total}
                  </span>
                </div>
                <div className="jho-progress">
                  <div
                    className="jho-progressCurrent"
                    style={{ width: (gameNodeTaskData.finish / gameNodeTaskData.total) * 80 }}
                  ></div>
                </div>
              </div>
            ) : (
              <div className="jho-receive" onClick={() => inviteFriendFun(2)}>
                {intl.get('toInvite')}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="jho-main" style={{ display: currentTab === 0 ? 'block' : 'none' }}>
        <div className="jho-blank2"></div>
        <div className="jho-invite">
          <div className="jho-myInvite">
            <div>{intl.get('myCourage')}</div>
          </div>
          <div className="jho-inviteDetail">
            <div className="receiveAll" onClick={() => lookDetail(1)}>
              {intl.get('viewDetails')}
            </div>
            <div className="jho-identity">
              <div className="jho-identityExpl">{intl.get('seasonDescription')}</div>
              <div className="jho-identityDetail">
                <div>{intl.get('adventurer')}</div>
                <div>{intl.get('explorer')}</div>
              </div>
            </div>
            <div className="jho-quarterIncome">
              <div className="jho-quarterTitle">
                <div>
                  {intl.get('d')}
                  {seasonData.season_number}
                  {intl.get('seasonIncome')}
                </div>
                <div className="jho-seasonEnd">
                  {intl.get('countdownSeason')}
                  {seasonData.countdownTime || '--'}
                </div>
              </div>
              <div className="jho-energyDetailCount">{seasonData.extend_reward}</div>
            </div>
            <div className="jho-quarterIncome">
              <div>
                <div className="jho-myEnergy">
                  <div>{intl.get('myCourage')}</div>
                  <div className="jho-energyProgress">
                    <img src={energyLogo} alt="" />
                    <div className="jho-energy">{seasonData.energy || 0}</div>
                    <div
                      className="jho-currentEnergy"
                      style={{
                        width: (seasonData.energy / seasonData.nft2_ask) * 93
                      }}
                    ></div>
                    <div className="jho-totalEnergy"></div>
                  </div>
                </div>
                <div className="jho-seasonEnd">
                  {intl.get('countdownSeason')}
                  {seasonData.countdownTime || '--'}
                </div>
              </div>
              <div className="jho-myIdentity">
                <img src={seasonData.nft1_state === 1 ? adventurerGrey : adventurerBright} alt="" />
                <img src={seasonData.nft2_state === 1 ? explorerGrey : explorerBright} alt="" />
              </div>
            </div>
            <div className="jho-energyDetails">
              <div className="jho-energyDetailsItem">
                <div>
                  {Number(seasonData.energy) >= Number(seasonData.nft1_ask)
                    ? intl.get('courageHave')
                    : intl.get('courageNot')}
                  {seasonData.nft1_ask}
                </div>
                <div>{intl.get('assigned')}</div>
                <div className="jho-energyDetailCount">{seasonData.nft1Aura}</div>
                <div
                  className={seasonData.nft1Aura !== 0 ? 'jho-receive' : 'jho-receiveGrey'}
                  onClick={() => receive(seasonData.nft1Aura, 1)}
                >
                  {intl.get('receive')}
                </div>
              </div>
              <div className="jho-energyDetailsItem">
                <div>
                  {Number(seasonData.energy) >= Number(seasonData.nft2_ask)
                    ? intl.get('courageHave')
                    : intl.get('courageNot')}
                  {seasonData.nft2_ask}
                </div>
                <div>{intl.get('assigned')}</div>
                <div className="jho-energyDetailCount">{seasonData.nft2Aura}</div>
                <div
                  className={seasonData.nft2Aura !== 0 ? 'jho-receive' : 'jho-receiveGrey'}
                  onClick={() => receive(seasonData.nft2Aura, 2)}
                >
                  {intl.get('receive')}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="jho-blank2"></div>
        <div className="jho-invite">
          <div className="jho-myInvite">
            <div>{intl.get('myInvitation')}</div>
          </div>
          <div className="jho-inviteDetail">
            <div className="receiveAll" onClick={() => lookDetail(2)}>
              {intl.get('viewDetails')}
            </div>
            <div className="jho-inviteData">
              <div className="jho-inviteP">
                <div>{intl.get('successInvited')}</div>
                <div>{promoteData.direct_drive}</div>
              </div>
              <div className="jho-inviteP">
                <div>{intl.get('rewarded')}</div>
                <div>{promoteData.oneselfLpTotal}</div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="jho-blank3"></div> */}
        {/* <div className="jho-inviteFriend">
          <div onClick={() => inviteFriendFun(1)}>{intl.get('invitedFriends')}</div>
        </div> */}
      </div>
      {currentTab === 2 ? <GameNode /> : ''}
      <div className="jho-blank3"></div>
      <div className="jho-inviteFriend">
        <div onClick={() => inviteFriendFun(1)}>{intl.get('invitedFriends')}</div>
      </div>
      <Footer url={window.location.href} />
      <div className="modal" style={{ display: flagError ? 'flex' : 'none' }}>
        <div className="modalContent">
          <div style={{ color: 'red', fontSize: 12 }}>{messageError}</div>
        </div>
      </div>
      <div className="receiveModal" style={{ display: inviteFriendFlag ? 'flex' : 'none' }}>
        <div className="receiveContent">
          <div>{intl.get('copyLink')}</div>
          <div>
            {intl.get('invitationLink')}
            {inviteUrl}/?paddr={userAddress}
          </div>
          <div>
            {countdown}
            {intl.get('close')}
          </div>
        </div>
      </div>
    </>
  )
}

export default JianghuOrder
