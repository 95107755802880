import React, { useState, useEffect, useRef } from 'react'
import AppBody from '../AppBody'
import logo from './img/logo.png'
import close from './img/close.png'
// import arrow from '../../assets/images/arrow.png'
import { getUserAddress, execute, getDepositContract, allowanceLP, approveLP } from '../../utils/playerAddr'
import { serverLink } from '../../store/constants'
import Spin from './Spin'
import Decimal from 'decimal.js-light'
import { JSBI } from '@uniswap/sdk'
import intl from 'react-intl-universal'
// import { Input as NumericalInput } from '../NumericalInput'

const lpAddressObj = {
  'AURA/USDT': '0x4A86Fd3B397e77340F22D9C92c34eEA20661537d',
  'HJW/USDT': '0x4EE5Ed6250320359Ff21b19b1135b5f6fDE44a7a'
}
const pledgeAddr = '0xD3bD27a7bDFf6C27ca0F93038322C4153394C30e'
const BankIncome = props => {
  const [countdown, setCountdown] = useState(2)
  const [LPData, setLPData] = useState(0)
  const [incomeData, setIncomeData] = useState(0)
  const [addr, setAddr] = useState('')
  const [flag, setFlag] = useState(false)
  const [flagDao, setFlagDao] = useState(false)
  const [flagError, setFlagError] = useState(false)
  const [messageError, setMessageError] = useState('')
  const [loading, setLoading] = useState(false)
  const [lockData, setLockData] = useState({ regular_list: [] })
  const [lockWay, setLockWay] = useState([{ duration: 0 }])
  const [daoAccount, setDaoAccount] = useState(0)
  const [lockWayIndex, setLockWayIndex] = useState(0)
  const [inputValue, setInputValue] = useState('')
  const [rewardToken, setRewardToken] = useState('')
  const [token, setToken] = useState('')
  const [energy, setEnergy] = useState(0)
  const time = useRef(null)
  // const allExtract = () => {
  //   props.history.push({
  //     pathname: './remove/0x0B6F8848fCA58031fd612dDfc26ABA460A4929aE/0x4572ac017fD735f9825eD7cA4488b93FbcF802f0'
  //   })
  // }
  // const addFlow = () => {
  //   props.history.push({ pathname: './add' })
  // }
  const goFlow = () => {
    props.history.push({ pathname: './add' })
  }

  //倒计时
  const countdownFun = () => {
    setCountdown(2)
    time.current = setInterval(() => {
      setCountdown(countdown => countdown - 1)
    }, 1000)
  }
  const message = msg => {
    setLoading(false)
    setFlagError(true)
    setMessageError(msg)
    countdownFun()
  }
  //收益量
  const getIncomeData = async (addr, rewardToken) => {
    try {
      const res = await fetch(`${serverLink}/api/get-lp-reward-amount/${addr}?token=${rewardToken}
      `).then(res => res.json())
      if (res.code === 0) {
        setIncomeData(res.data.total_reward)
      } else {
        message(res.msg)
      }
    } catch (e) {
      message(e.message)
    }
  }
  //提取收益
  const getExtract = async () => {
    if (incomeData == 0) {
      message(intl.get('noIncomeW'))
      return
    }
    setLoading(true)
    try {
      const res = await fetch(`${serverLink}/api/draw-lp-reward/${addr}/${rewardToken}
      `).then(res => res.json())
      if (res.code === 0) {
        setLoading(false)
        setFlag(true)
        countdownFun()
        getIncomeData(addr, rewardToken)
      } else {
        message(res.msg)
      }
    } catch (e) {
      message(e.message)
    }
  }
  //锁仓方式数据
  const getLockWay = async token => {
    try {
      const res = await fetch(`${serverLink}/api/lp-config-info?token=${token}
      `).then(res => res.json())
      if (res.code === 0) {
        if (res.data) {
          let indexNo = -1
          res.data.map((item, index) => {
            item.flag = false
            item.day = item.duration / 24 / 3600
            if (item.duration === 0 && item.weight === 0) {
              indexNo = index
            }
          })
          //hjw 不显示活期
          if (indexNo !== -1) {
            res.data.splice(indexNo, 1)
          }
          res.data.length > 0 ? (res.data[0].flag = true) : (res.data = [{ duration: 0 }])
          setLockWay(res.data)
        }
      } else {
        message(res.msg)
      }
    } catch (e) {
      message(e.message)
    }
  }
  //锁仓数据
  const getlockData = async (addr, token) => {
    try {
      const res = await fetch(`${serverLink}/api/get-lp-current-regular/${addr}?token=${token}
      `).then(res => res.json())
      if (res.code === 0) {
        if (!res.data.regular_list) {
          res.data.regular_list = []
        }
        setLockData(res.data)
      } else {
        message(res.msg)
      }
    } catch (e) {
      message(e.message)
    }
  }
  //能量值
  const getEnergy = (index, value) => {
    let energy = lockWay[index].energy_ratio * lockWay[index].lp_price_in_usd * Number(value)
    setEnergy(Math.floor(energy * 10000) / 10000)
  }
  //选择锁仓方式
  const selectLockWay = index => {
    // console.log(index)
    setLockWayIndex(index)
    let list = lockWay.concat()
    list.map((item, index1) => {
      if (index1 === index) {
        list[index1].flag = true
      } else {
        list[index1].flag = false
      }
    })
    if (inputValue) {
      getEnergy(index, inputValue)
    }
    setLockWay(list)
  }
  const goPledge = () => {
    setInputValue('')
    setFlagDao(true)
  }
  const closeModal = () => {
    setFlagDao(false)
  }
  //提取LP
  let depositContract = getDepositContract(pledgeAddr)
  const getExtractLP = async () => {
    if (lockData.current_us < 1) {
      message(intl.get('noLPW'))
      return
    }
    let extractLPParam = JSBI.BigInt(lockData.current_us * Math.pow(10, 18)).toString()
    setLoading(true)
    try {
      await depositContract.methods.withdraw(lpAddressObj[token], extractLPParam).send({ from: addr })
      setTimeout(function() {
        setLoading(false)
        setFlag(true)
        countdownFun()
        getIncomeData(addr, rewardToken)
        getlockData(addr, token)
        execute(addr, lpAddressObj[token]).then(v => {
          setDaoAccount(Math.floor(v * 100) / 100)
        })
      }, 6000)
    } catch (e) {
      message(e.message)
    }
  }
  //点击modal 里质押
  const getPledgeLP = async () => {
    if (inputValue === '') {
      message(intl.get('fillPA'))
      return
    }
    if (Number(inputValue) > Number(daoAccount)) {
      message(intl.get('beyondMax'))
      return
    }
    // 当前地址是否将LP授权了足够数额给质押合约
    let allowanceLPAcount = await allowanceLP(addr, pledgeAddr, lpAddressObj[token])
    let amount = 90000000000000000000000000000000000000000000000000000000000
    let amount1 = new Decimal(amount).mul(new Decimal(10).pow(5)).toFixed()
    if (Number(inputValue) > Number(allowanceLPAcount)) {
      // setFlagError(true)
      // setMessageError('请先授权')
      // countdownFun()
      try {
        await approveLP(pledgeAddr, amount1, addr, lpAddressObj[token])
      } catch (e) {
        message(e.message)
      }
      return
    }

    let inputValueParam = JSBI.BigInt(Number(inputValue) * Math.pow(10, 18)).toString()
    setLoading(true)
    setFlagDao(false)
    try {
      await depositContract.methods
        .deposit(lpAddressObj[token], inputValueParam, lockWay[lockWayIndex].day)
        .send({ from: addr })
      setTimeout(function() {
        message(intl.get('pledgeSucc'))
        getIncomeData(addr, rewardToken)
        getlockData(addr, token)
        execute(addr, lpAddressObj[token]).then(v => {
          setDaoAccount(Math.floor(v * 100) / 100)
        })
      }, 6000)
    } catch (e) {
      message(e.message)
    }
  }
  //质押里 点击最大
  const getBigLP = () => {
    setInputValue(daoAccount)
    getEnergy(lockWayIndex, daoAccount)
  }
  //质押modal 输入框变化
  const changeInputValue = e => {
    e.persist()
    setInputValue(e.target.value)
    getEnergy(lockWayIndex, e.target.value)
  }
  useEffect(() => {
    if (countdown < 0) {
      setFlag(false)
      setFlagError(false)
      setMessageError('')
      clearInterval(time.current)
    }
  }, [countdown])
  useEffect(() => {
    let tokenTvl = localStorage.getItem('tokenTvl')
    let rewardTokenTvl = localStorage.getItem('rewardTokenTvl')
    setToken(tokenTvl)
    setRewardToken(rewardTokenTvl)
    getUserAddress().then(r => {
      setAddr(r)
      getIncomeData(r, rewardTokenTvl)
      getlockData(r, tokenTvl)
      execute(r, lpAddressObj[tokenTvl]).then(v => {
        setDaoAccount(Math.floor(v * 100) / 100)
      })
    })
    getLockWay(tokenTvl)
    // document.title = '江湖银行(LP)'
  }, [])

  return (
    <>
      {loading ? (
        <div className="spin-bg">
          <Spin />
        </div>
      ) : null}
      <AppBody></AppBody>
      {/* <a href="#/nameList">
        <div className="goNameList">
          <span>查看排行</span>
          <img src={arrow} alt="" />
        </div>
      </a> */}
      <div className="empty"></div>
      <div className="main">
        <div className="partAura">
          <div className="extract">
            <img alt="logo" src={logo}></img>
            <div onClick={() => getExtract()}>{intl.get('withdrawal')}</div>
          </div>
          <div className="income">
            {rewardToken} {intl.get('income')}
          </div>
          <div className="incomeData">{incomeData}</div>
        </div>
        <div className="empty"></div>
        <div className="partLP">
          <div className="extract">
            <img alt="logo" src={logo}></img>
            <div onClick={() => goFlow()}>{intl.get('add')}</div>
          </div>
          <div className="income">
            {token} LP {intl.get('mining')}
          </div>
          <div className="incomeData">{daoAccount}</div>
        </div>
        <div className="empty"></div>
        <div className="partLP">
          <div className="extract">
            <img alt="logo" src={logo}></img>
            <div onClick={() => goPledge()}>{intl.get('pledge')}</div>
          </div>
          <div className="income">
            DAO{intl.get('voucher')}
            <span>*{intl.get('generateLP')}</span>
          </div>
          <div className="incomeData">{lockData.current_now}</div>
        </div>
        <div className="empty"></div>
        <div className="lock">
          <div className="lockTitle">{intl.get('currentLock')}</div>
          <div className="lockLP">
            <div className="lockLPData">
              <div>{intl.get('currentLP')}</div>
              <div>{lockData.current_now}</div>
            </div>
            <div className="lockLPData">
              <div>{intl.get('extractableLP')}</div>
              <div>{lockData.current_us}</div>
            </div>
            <div className="extractLP" onClick={() => getExtractLP()}>
              {intl.get('withdrawal')}
            </div>
          </div>
          <div className="lockTitle">{intl.get('regularLock')}</div>
          <div className="lockRegular">
            {lockData.regular_list.map((item, index) => (
              <div key={index} className="lockRegularItem">
                <div className="lockRegularItemLeft">
                  <div>
                    {item.duration / 24 / 3600}
                    {intl.get('day')}
                  </div>
                  <div>{item.apy}%</div>
                </div>
                <div className="lockRegularItemRight">
                  <div>{intl.get('lockQuantity')}</div>
                  <div>{item.amount}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
        {/* <div className="all">
          <div onClick={() => allExtract()}>全部提取</div>
          <div onClick={() => addFlow()}>添加流动性</div>
        </div> */}
      </div>
      <div className="modal" style={{ display: flag ? 'flex' : 'none' }}>
        <div className="modalContent">
          <div>{intl.get('congratulations')}</div>
          <div>{intl.get('withdrawalS')}</div>
          <div>
            {countdown} {intl.get('close')}
          </div>
        </div>
      </div>

      <div className="modal" style={{ display: flagDao ? 'flex' : 'none' }}>
        <div className="modalContentDao">
          <img src={close} alt="" className="close" onClick={() => closeModal()} />
          <div className="modalContentDaoTitle">{intl.get('pledgeDao')}</div>
          <div className="modalContentDaoTitle2">{intl.get('SelectLockS')}</div>
          <div className="modalLockWay">
            {lockWay.map((item, index) => (
              <div
                key={index}
                onClick={() => selectLockWay(index)}
                style={{
                  color: item.flag ? '#F7479E' : '#909498',
                  border: item.flag ? '1px solid #F7479E' : '1px solid #E5E5E5'
                }}
              >
                {item.day === 0 ? intl.get('demand') : `${item.day}${intl.get('day')}`}
              </div>
            ))}
          </div>
          <div className="modalLockAPY">
            {lockWay[lockWayIndex].duration === 0 ? intl.get('demand') : intl.get('regular')} {intl.get('incomeWeight')}
            <span>x{lockWay.length > 0 ? lockWay[lockWayIndex].weight : ''}</span>
          </div>
          <div className="modalLockAPY">
            {lockWay[lockWayIndex].duration === 0 ? intl.get('demand') : intl.get('regular')} APY：
            <span>{lockWay.length > 0 ? lockWay[lockWayIndex].apy : ''}%</span>
          </div>
          <div className="modalEnergy">
            {intl.get('energyAvailable')}
            <span>{energy}</span>
          </div>
          <div className="modalAddCount">
            <div>{intl.get('addsquantity')}</div>
            <div>
              {intl.get('availableBalance')}
              <span>{daoAccount}</span>
            </div>
          </div>
          <div className="modalInput">
            <input
              inputMode="decimal"
              type="number"
              pattern="^(0x[a-fA-F0-9]{40})$"
              placeholder="LP"
              className="input"
              onChange={e => changeInputValue(e)}
              value={inputValue}
            ></input>
            <div onClick={() => getBigLP()}>{intl.get('max')}</div>
          </div>
          <div className="modalPledge" onClick={() => getPledgeLP()}>
            {intl.get('pledge')}
          </div>
        </div>
      </div>
      <div className="modal" style={{ display: flagError ? 'flex' : 'none' }}>
        <div className="modalContent">
          <div style={{ color: 'red', fontSize: 12 }}>{messageError}</div>
        </div>
      </div>
    </>
  )
}

export default BankIncome
